import React , { useState , Fragment , useRef} from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import CardModal from '../CardModal';
//NFT MARKETS
import tangiblenfts from '../../../assets/images/box-item/nftmarkets/tangiblenfts.png'
//PLAY 2 EARN
import twitchytides from '../../../assets/images/box-item/playtoearn/twitchytides.png'
import cigalepixel from '../../../assets/images/box-item/playtoearn/cigalepixel.png'
import zombiecoin from '../../../assets/images/box-item/playtoearn/zombiecoin.png'
import alienworlds from '../../../assets/images/box-item/playtoearn/alienworlds.png'
import spinniaworld from '../../../assets/images/box-item/playtoearn/spinniaworld.png'
import apexconquest from '../../../assets/images/box-item/playtoearn/apexconquest.png'
//import beastgarden from '../../../assets/images/box-item/playtoearn/beastgarden.png'
import blockchainbrawlers from '../../../assets/images/box-item/playtoearn/blockchainbrawlers.png'
import blockchainrpg from '../../../assets/images/box-item/playtoearn/blockchainrpg.png'
import citystatesmedieval from '../../../assets/images/box-item/playtoearn/citystatesmedieval.png'
import clashdome from '../../../assets/images/box-item/playtoearn/clashdome.png'
//import coinpirates from '../../../assets/images/box-item/playtoearn/coinpirates.png'
import colonizemars from '../../../assets/images/box-item/playtoearn/colonizemars.png'
import cosmicclash from '../../../assets/images/box-item/playtoearn/cosmicclash.png'
import cosmoseleven from '../../../assets/images/box-item/playtoearn/cosmoseleven.png'
import musicmogul from '../../../assets/images/box-item/playtoearn/musicmogul.png'
import cryptomonkeys from '../../../assets/images/box-item/playtoearn/cryptomonkeys.png'
import darkcountry from '../../../assets/images/box-item/playtoearn/darkcountry.png'
import spintycoon from '../../../assets/images/box-item/playtoearn/spintycoon.png'
//import desertfarm from '../../../assets/images/box-item/playtoearn/desertfarm.png'
import farfromhome from '../../../assets/images/box-item/playtoearn/farfromhome.png'
//import funnycats from '../../../assets/images/box-item/playtoearn/funnycats.png'
import galactic from '../../../assets/images/box-item/playtoearn/galactic123.png'
import gameofstonks from '../../../assets/images/box-item/playtoearn/gameofstonks.png'
import gnocity from '../../../assets/images/box-item/playtoearn/gnocity.png'
import goldmand from '../../../assets/images/box-item/playtoearn/goldmand.png'
import immersys from '../../../assets/images/box-item/playtoearn/immersys.png'
//import journeytogodhood from '../../../assets/images/box-item/playtoearn/journeytogodhood.png'
import kogs from '../../../assets/images/box-item/playtoearn/kogs.png'
import koloblok from '../../../assets/images/box-item/playtoearn/koloblok.png'
//import machinearmy from '../../../assets/images/box-item/playtoearn/machinearmy.png'
import milliononmars from '../../../assets/images/box-item/playtoearn/milliononmars.png'
import monsterfight from '../../../assets/images/box-item/playtoearn/monsterfight.png'
import nftbattleminers from '../../../assets/images/box-item/playtoearn/nftbattleminers.png'
import nftpandaworldoffantasy from '../../../assets/images/box-item/playtoearn/nftpandaworldoffantasy.png'
import novarally from '../../../assets/images/box-item/playtoearn/novarally.png'
import novopangea from '../../../assets/images/box-item/playtoearn/novopangea.png'
//import oceanplanet from '../../../assets/images/box-item/playtoearn/oceanplanet.png'
import pagangods from '../../../assets/images/box-item/playtoearn/pagangods.png'
import prospectors from '../../../assets/images/box-item/playtoearn/prospectors.png'
import radaquest from '../../../assets/images/box-item/playtoearn/radaquest.png'
import rplanet from '../../../assets/images/box-item/playtoearn/rplanet.png'
import spaceheroes from '../../../assets/images/box-item/playtoearn/spaceheroes.png'
import splinterlands from '../../../assets/images/box-item/playtoearn/splinterlands.png'
import theadventurersguild from '../../../assets/images/box-item/playtoearn/theadventurersguild.png'
import theforgearena from '../../../assets/images/box-item/playtoearn/theforgearena.png'
import theupliftworld from '../../../assets/images/box-item/playtoearn/theupliftworld.png'
import trainofthecentury from '../../../assets/images/box-item/playtoearn/trainofthecentury.png'
import warsaken from '../../../assets/images/box-item/playtoearn/warsaken.png'
import wombatdungeonmaster from '../../../assets/images/box-item/playtoearn/wombatdungeonmaster.png'
import xpansion from '../../../assets/images/box-item/playtoearn/xpansion.png'
import zendodo from '../../../assets/images/box-item/playtoearn/zendodo.png'
import zosnew from '../../../assets/images/box-item/playtoearn/zos.png'
import neftyblocks from '../../../assets/images/box-item/marketplaces/neftyblocks.png'
import virl from '../../../assets/images/box-item/marketplaces/virl.png'
import atomichub from '../../../assets/images/box-item/marketplaces/atomichub.png'
import nfthive from '../../../assets/images/box-item/marketplaces/nfthive.png'
import droppp from '../../../assets/images/box-item/marketplaces/droppp.png'
import waxstash from '../../../assets/images/box-item/marketplaces/waxstash.png'
import yoshidrops from '../../../assets/images/box-item/marketplaces/yoshidrops.png'
import lgndart from '../../../assets/images/box-item/marketplaces/lgndart.png'
import chainchamps from '../../../assets/images/box-item/marketplaces/chainchamps.png'
import waxdeveloperhive from '../../../assets/images/box-item/developerresourcestools/waxdeveloperhive.png'
import waxdao from '../../../assets/images/box-item/developerresourcestools/waxdao.png'
import headsupmonitoring from '../../../assets/images/box-item/developerresourcestools/headsupmonitoring.png'
import eospherehowto from '../../../assets/images/box-item/developerresourcestools/eospherehowto.png'
import awscdk from '../../../assets/images/box-item/developerresourcestools/awscdk.png'
import waxmainnetresourceusage from '../../../assets/images/box-item/developerresourcestools/waxmainnetresourceusage.png'
import eosswedendevelopersresources from '../../../assets/images/box-item/developerresourcestools/eosswedendevelopersresources.png'
import voteproxyresearchportal from '../../../assets/images/box-item/developerresourcestools/voteproxyresearchportal.png'
import atomicassetsdocumentation from '../../../assets/images/box-item/developerresourcestools/atomicassetsdocumentation.png'
import blockproducerresearchportal from '../../../assets/images/box-item/developerresourcestools/blockproducerresearchportal.png'
import blockproducervalidator from '../../../assets/images/box-item/developerresourcestools/blockproducervalidator.png'
import blockproducerbenchmark from '../../../assets/images/box-item/developerresourcestools/blockproducerbenchmark.png'
import blockproducerreliabilitytracker from '../../../assets/images/box-item/developerresourcestools/blockproducerreliabilitytracker.png'
import greymassapi from '../../../assets/images/box-item/developerresourcestools/greymassapi.png'
import sentnl from '../../../assets/images/box-item/developerresourcestools/sentnl.png'
import dapplica from '../../../assets/images/box-item/developerresourcestools/dapplica.png'
//DEXs
import adex from '../../../assets/images/box-item/dexs/adex.png'
import defibox from '../../../assets/images/box-item/dexs/defibox.png'
import alcor from '../../../assets/images/box-item/dexs/alcor.png'
import tacoswap from '../../../assets/images/box-item/dexs/tacoswap.png'
import waxonedge from '../../../assets/images/box-item/dexs/waxonedge.png'
//Exchanges
import uniswap from '../../../assets/images/box-item/exchanges/uniswap.png'
import bingx from '../../../assets/images/box-item/exchanges/bingx.png'
import xtcom from '../../../assets/images/box-item/exchanges/xtcom.png'
import bitmart from '../../../assets/images/box-item/exchanges/bitmart.png'
import okx from '../../../assets/images/box-item/exchanges/okx.png'
import bitget from '../../../assets/images/box-item/exchanges/bitget.png'
import bybit from '../../../assets/images/box-item/exchanges/bybit.png'
import binance from '../../../assets/images/box-item/exchanges/binance.png'
import bittrex from '../../../assets/images/box-item/exchanges/bittrex.png'
import huobi from '../../../assets/images/box-item/exchanges/huobi.png'
import kucoin from '../../../assets/images/box-item/exchanges/kucoin.png'
//DeFi
import waxdefi from '../../../assets/images/box-item/defi/waxdefi.png'
import alcorexchange from '../../../assets/images/box-item/defi/alcorexchange.png'
import defiboxd from '../../../assets/images/box-item/defi/defibox.png'
import waxfusion from '../../../assets/images/box-item/defi/waxfusion.png'
//NFT Tools
import waxgg from '../../../assets/images/box-item/nfttools/waxgg.png'
import tacoapp from '../../../assets/images/box-item/nfttools/tacoapp.png'
import neftyblocksnftcreator from '../../../assets/images/box-item/nfttools/neftyblocksnftcreator.png'
import atomicassetsnftcreator from '../../../assets/images/box-item/nfttools/atomicassetsnftcreator.png'
import bountyblokdroptool from '../../../assets/images/box-item/nfttools/bountyblokdroptool.png'
import packbreak from '../../../assets/images/box-item/nfttools/packbreak.png'
//Wallets
import anchorwallet from '../../../assets/images/box-item/wallets/anchorwallet.png'
import cloudwallet from '../../../assets/images/box-item/wallets/cloudwallet.png'
import wombat from '../../../assets/images/box-item/wallets/wombat.png'
//Market Ranking Sites
import cryptoslam from '../../../assets/images/box-item/marketrankingsites/cryptoslam.png'
import dapp from '../../../assets/images/box-item/marketrankingsites/dapp.png'
import dappradar from '../../../assets/images/box-item/marketrankingsites/dappradar.png'
import niftycap from '../../../assets/images/box-item/marketrankingsites/niftycap.png'
import reviewdapp from '../../../assets/images/box-item/marketrankingsites/reviewdapp.png'
import waxmarketcap from '../../../assets/images/box-item/marketrankingsites/waxmarketcap.png'
//Educational
import anyobservation from '../../../assets/images/box-item/educational/anyobservation.png'
import waxhub from '../../../assets/images/box-item/educational/waxhub.png'
import illusivegg from '../../../assets/images/box-item/educational/illusivegg.png'
import waxzilla from '../../../assets/images/box-item/educational/waxzilla.png'
//FUNDING
import waxlabsfunding from '../../../assets/images/box-item/funding/waxlabsfunding.png'
//BLOCK EXPLORERS
import waxblock from '../../../assets/images/box-item/blockexplorers/waxblock.png'
import eosauthority from '../../../assets/images/box-item/blockexplorers/eosauthority.png'
import eosq from '../../../assets/images/box-item/blockexplorers/eosq.png'
import eosx from '../../../assets/images/box-item/blockexplorers/eosx.png'
//ANDROID - IOS APPS
import mynifties from '../../../assets/images/box-item/androidios/mynifties.png'
import niftydrops from '../../../assets/images/box-item/androidios/niftydrops.png'
//import shynapp from '../../../assets/images/box-item/androidios/shynapp.png'
import tacoappai from '../../../assets/images/box-item/androidios/tacoapp.png'
import tokenhead from '../../../assets/images/box-item/androidios/tokenhead.png'
import waxtracker from '../../../assets/images/box-item/androidios/waxtracker.png'
//live drops schedule
import horizonwhite from '../../../assets/images/box-item/livedropschedule/horizonwhite.png'
import nftinsider from '../../../assets/images/box-item/livedropschedule/nftinsider.png'
import niftydropsl from '../../../assets/images/box-item/livedropschedule/niftydrops.png'
import tokengamer from '../../../assets/images/box-item/livedropschedule/tokengamer.png'
// Community
import wuffi from '../../../assets/images/box-item/community/wuffi.jpeg'
import launchbagz from '../../../assets/images/box-item/community/launchbagz.png'
import wuffitap from '../../../assets/images/box-item/community/wuffitap.png'


    
    const ItemContent = props => {
            
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "Play to Earn",
            },
            {
                id: 2,
                title: "NFT Market Place",
            },
            {
                id: 3,
                title: "DEX",
            },
            {
                id: 4,
                title: "Exchange",
            },
            {
                id: 5,
                title: "DeFi",
            },
            {
                id: 6,
                title: "Block Explorer",
            },
            {
                id: 7,
                title: "Developer Resource",
            },
            {
                id: 8,
                title: "NFT Tool's",
            },
 	        {
                id: 9,
                title: "Market Ranking Site",
            },
            {
                id: 10,
                title: "Mobile Apps",
            },
            {
                id: 11,
                title: "Live Drops Schedules",
            },
            {
                id: 12,
                title: "Wallet",
            },
            {
                id: 13,
                title: "Funding",
            },
            {
                id: 14,
                title: "Community",
            },
            // {
            //     id: 15,
            //     title: "Learn",
            // },
        ]
    )
    const [dataPanel] = useState(
        [
            // Play to Earn
            {
                id: 1,
                dataContent: [
                    {
                        id: 1,
                        img: alienworlds,
                        title: "1",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Alien Worlds is a Digital Item Metaverse in Faraway Space. Own land on distant planets, explore and find strange artefacts, mine for Trilium with hyper-advanced tools or fight using alien weapons.",
                        imgAuthor: alienworlds,
                        nameAuthor: "Alien Worlds",
                        twitterLink: "https://www.twitter.com/alienworlds",
                        websiteLink: "https://alienworlds.io/",
                        collectionName: "alien.worlds",
                    },
                    {
                        id: 2,
                        img: spintycoon,
                        title: "2",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Shape Your City, Crush Your Rivals, and Reign Supreme! ",
                        imgAuthor: spintycoon,
                        nameAuthor: "SpinTycoon",
                        twitterLink: "https://twitter.com/spintycoon",
                        websiteLink: "https://www.spintycoon.com/",
                        collectionName: "null",
                    },
                    {
                        id: 3,
                        img: blockchainbrawlers,
                        title: "3",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "From the top rope, it's The Screaming Frog! He lands a devastating leg drop on Ivan 'Beef' Stroganov. Looks like Stroganov won't make it to dinner tonight! Meet the Blockchain Brawlers™, the most hilarious and entertaining series of NFTs in metaverse history. We're talking about a ragtag brigade of high-flying heels that includes The Iron Pole, Crocodile Dundalk and Juggernaut (to name a few) that'll help you wrestle your way to NFT supremacy. Created by WAX Studios, 'Founders Edition' Legendary 1-of-1 Blockchain Brawlers' over-the-top costumes and personalities aren't the only things that make this limited-edition collection special. Each brawler plays an integral role in the Blockchain Brawlers play-to-earn game where they're grappling and elbowing their (and your) way to fame and fortune. The blockchain will never be the same, the rowdiest game in the metaverse is here to knock some heads together!",
                        imgAuthor: blockchainbrawlers,
                        nameAuthor: "Blockchain Brawlers",
                        twitterLink: "https://twitter.com/bc_brawlers",
                        websiteLink: "https://bcbrawlers.com/",
                        collectionName: "bcbrawlers",
                    },
                    {
                        id: 4,
                        img: blockchainrpg,
                        title: "4",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "BlockchainRPG is a Digital Item Metaverse in the faraway lands of Aurum. Hunt monsters, gain resources, craft better tools, and compete against others. Let your blockchain adventure begin!",
                        imgAuthor: blockchainrpg,
                        nameAuthor: "Blockchain RPG",
                        twitterLink: "https://twitter.com/BlockchainRPG",
                        websiteLink: "https://blockchainrpg.io/",
                        collectionName: "blokchainrpg",
                    },
                    {
                        id: 5,
                        img: spinniaworld,
                        title: "5",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "An economic Spin-to-Earn game developed on the WAX blockchain",
                        imgAuthor: spinniaworld,
                        nameAuthor: "Spinnia World",
                        twitterLink: "https://twitter.com/spinnia",
                        websiteLink: "https://spinnia.io/",
                        collectionName: "spinniaworld",
                    },
                    {
                        id: 6,
                        img: clashdome,
                        title: "6",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Rug Pool is just one game inside the game portal ClashDome. The portal is in constant expansion and already hosts a bunch of other casual PvP games: Puzzles, Match3, Tower Defense. Compete against other players and earn the token LUDIO each time you win. Besides the Free Tier we also host more competitive matches where the most confident players pay a WAX entry fee and get it doubled (-5% fee) if they win. Cool thing is ClashDome keeps it quite casual and it's an **easy to learn project**. The game is only as demanding as you want, meaning you have enough features to deep dive into or keep it as casual as your busy agenda allows.",
                        imgAuthor: clashdome,
                        nameAuthor: "Clash Dome",
                        twitterLink: "https://twitter.com/clash_dome",
                        websiteLink: "https://www.clashdome.io/",
                        collectionName: "clashdomenft",
                    },
                    {
                        id: 7,
                        img: zombiecoin,
                        title: "7",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Zombiecoin is an NFT resource gathering and crafting game on the WAX blockchain. Players perform work tasks to gather various resources. The resources are used in crafting and upgrading to higher tier materials. For example, walls can be made from wood and combined with other parts to build a house. You can also trade the resources and assets on the secondary market. Villagers gather wood from the dense forests of Zombieland. Explorers can find Mines that grants the player a Mine Owner Fee paid out in WAX upon the depletion of the mine's resources. Explorers also gather other resources such as rocks and broken bottles. The Zombie Miners are the only characters that can withstand the toxic environment in the mines. They mine for ZBC and special items like jewellery. ZBC is the game currency used for crafting and purchasing items in the in-game shop. It is a token listed on the Alcor exchange.",
                        imgAuthor: zombiecoin,
                        nameAuthor: "Zombie Coin",
                        twitterLink: "https://x.com/ZombiecoinIO",
                        websiteLink: "https://www.zombiecoin.io/",
                        collectionName: "zombiecoinzz",
                    },
                    {
                        id: 7,
                        img: twitchytides,
                        title: "7",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "The Wacky Twitch stream game Twitchy Tides.. Where you do you best to keep you fish alive with sharks, JellyFish, and one angry worm on a hook! Will you survive?",
                        imgAuthor: twitchytides,
                        nameAuthor: "Twitchy Tides",
                        twitterLink: "https://x.com/TwitchyTides",
                        websiteLink: "https://www.twitchytides.io/",
                        collectionName: "twitchytides",
                    },
                    {
                        id: 7,
                        img: cigalepixel,
                        title: "7",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "In Arsuk, winter is eternal. Snow is the main resource to trade goods, and monsters are threatening the safety of the small town...You are one of the heroes sent to deal with those issues ! Fight, craft your equipment, train and get better skills and be the best fighter in this new old-school RPG on Discord ! Pixel Fighters is your old school, turn-based RPG where NFTs are your gear ! Fight monsters, level up and improve your skills, take jobs, craft new gear and be the best warrior out there !",
                        imgAuthor: cigalepixel,
                        nameAuthor: "Cigal Pixel",
                        twitterLink: "https://x.com/CigalePixel",
                        websiteLink: "https://www.cigalepixel.com/",
                        collectionName: "cigalepixeld",
                    },
                    {
                        id: 7,
                        img: apexconquest,
                        title: "7",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "",
                        imgAuthor: apexconquest,
                        nameAuthor: "Apex Conquest",
                        twitterLink: "https://twitter.com/ApexConquest",
                        websiteLink: "https://apexconquest.com/",
                        collectionName: "apexconquest",
                    },
                    {
                        id: 8,
                        img: colonizemars,
                        title: "8",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Own part of the first-ever mars colony on the blockchain! Purchase supply crates, unlock unique cards, and upgrade them for a chance at owning different parts of the colony forever.",
                        imgAuthor: colonizemars,
                        nameAuthor: "Colonize Mars",
                        twitterLink: "https://twitter.com/ColonizeMarsNFT",
                        websiteLink: "https://mars.cards/",
                        collectionName: "colonizemars",
                    },
                    {
                        id: 9,
                        img: cosmicclash,
                        title: "9",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Come join the first play-and-own game to use the FGL meta token! Build your armada by trading NFTs on AtomicHub. Find the perfect fleet formation to defeat your enemies. Advance to higher level galaxies to unlock more powerful starships. Battle to the top of the leaderboard and win big prizes! Cosmic Clash is a collaboration between FGL and Mango Studios. Lucrative Gaming, aka 'FGL' was founded in 2007 and pioneered web games, helping web and Flash developers get their games published and distributed at a time when the web game market was considered the 'wild west'. FGL has worked with over 70,000 developers across web and mobile and has vast experience with making games successful across both platforms. FGL has successfully helped game developers make millions of dollars annually for over 14 years and we have no intention of slowing down.",
                        imgAuthor: cosmicclash,
                        nameAuthor: "Cosmic Clash",
                        twitterLink: "https://twitter.com/FGL_NFT",
                        websiteLink: "https://cosmicclash.io/",
                        collectionName: "cosmicclashx",
                    },
                    {
                        id: 10,
                        img: cosmoseleven,
                        title: "10",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Cosmos Eleven is one of the newest projects on WAX. If you are a fan of football you will be excited about our football manager metaverse! Welcome to the Cosmos. Get your top players, hire the best coaches, find prime medics and choose your tactics. Nonetheless, don't forget there are facilities you need to upgrade so each FAN will find his seat at the stadium. Join us for more info.",
                        imgAuthor: cosmoseleven,
                        nameAuthor: "Cosmos Eleven",
                        twitterLink: "https://twitter.com/Cosmos_E11even",
                        websiteLink: "https://cosmoseleven.com/",
                        collectionName: "cosmoseleven",
                    },
                    {
                        id: 11,
                        img: musicmogul,
                        title: "11",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "The ultimate tycoon game for music fans on WAX. Build your own NFT-powered record label across every musical genre.",
                        imgAuthor: musicmogul,
                        nameAuthor: "Music Mogul",
                        twitterLink: "https://twitter.com/musicmogul_io",
                        websiteLink: "https://play.musicmogul.io/",
                        collectionName: "musicmogul",
                    },
                    {
                        id: 12,
                        img: cryptomonkeys,
                        title: "12",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "cryptomonKeys is a freely distributed, community-driven, meme-rich digital trading card series based on NFT technology, here to disrupt the meme economy.",
                        imgAuthor: cryptomonkeys,
                        nameAuthor: "Crypto Monkeys",
                        twitterLink: "https://twitter.com/crypt0monKeys",
                        websiteLink: "https://www.cryptomonkeys.cc/",
                        collectionName: "crptomonkeys",
                    },
                    {
                        id: 13,
                        img: darkcountry,
                        title: "13",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "AMERICAN GOTHIC CARD GAME. HEROES COLLECTION.",
                        imgAuthor: darkcountry,
                        nameAuthor: "Dark Country",
                        twitterLink: "https://twitter.com/DarkCountryGame",
                        websiteLink: "https://darkcountry.io/",
                        collectionName: "darkcountryh",
                    },
                    {
                        id: 16,
                        img: farfromhome,
                        title: "16",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Far From Home is an upcoming space-based metaverse game, that will challenge its players to mine, build, and fight their way into the unknown vastness of the universe",
                        imgAuthor: farfromhome,
                        nameAuthor: "Far From Home",
                        twitterLink: "https://twitter.com/FarFromHomeNFT",
                        websiteLink: "https://farfromhome.io",
                        collectionName: "farfromhomes",
                    },
                    {
                        id: 19,
                        img: galactic,
                        title: "19",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "This is the Galactic 123 collection, and contains assets that provide bonuses and powerups within the Galactic 123 online videogame. Check website FAQ for details on individual assets.",
                        imgAuthor: galactic,
                        nameAuthor: "Galactic 123",
                        twitterLink: "https://twitter.com/galactic123now",
                        websiteLink: "https://galactic123.net/",
                        collectionName: "galactic123c",
                    },
                    // {
                    //     id: 20,
                    //     img: gameofstonks,
                    //     title: "20",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Your free space in the metaverse to customize with NFTs (outside too), do some quests, and invite some friends to see with a shortlink. Collect special items with extra in-game functions: Mining Pack - Earn Metaverse Coins Office Pet Pack - Give your favorite pet some life . Other starter packs and active drops can be found on Neftyblocks.com/c/Gameofstonks More multiplayer functions, play to earn, and events in the metaverse is in works. The game is still in alpha stage of development by @nickogibson",
                    //     imgAuthor: gameofstonks,
                    //     nameAuthor: "A Game of Stonks",
                    //     twitterLink: "https://twitter.com/i/events/1412502638252281856",
                    //     websiteLink: "https://mverse.cloud",
                    //     collectionName: "gameofstonks",
                    // },
                    {
                        id: 21,
                        img: gnocity,
                        title: "21",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "GNO City is a playable NFT trading card game. Collect and earn with our story mode or play head to head with another collector to win experience. Level up your characters today.",
                        imgAuthor: gnocity,
                        nameAuthor: "GNO City",
                        twitterLink: "https://twitter.com/gnocityio",
                        websiteLink: "https://gnocity.io/",
                        collectionName: "gnocityworld",
                    },
                    // {
                    //     id: 22,
                    //     img: goldmand,
                    //     title: "22",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Explore deep space with our game. The Metaverse is waiting for you. Choose a species, pick the planet and suitable land and start mining. The Big varieties for items and unique amulets.",
                    //     imgAuthor: goldmand,
                    //     nameAuthor: "GoldMand Game",
                    //     twitterLink: "https://twitter.com/goldmand_nft",
                    //     websiteLink: "https://goldmand.io",
                    //     collectionName: "goldmandgame",
                    // },
                    // {
                    //     id: 26,
                    //     img: immersys,
                    //     title: "26",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Immersys is a massive, all-encompassing Metaverse Ecosystem. Immersys aims to deliver a system that utilizes and expands on all layers of the metaverse. These layers are experience, discovery, creator economy, spatial computing, decentralization, and infrastructure. Our systems of immersion are being developed for the Next Generation of gaming, social interaction, advertising, commerce, and technology.",
                    //     imgAuthor: immersys,
                    //     nameAuthor: "Immersys",
                    //     twitterLink: "https://twitter.com/Immersys",
                    //     websiteLink: "https://immersys.io/",
                    //     collectionName: "immersys",
                    // },
                    {
                        id: 29,
                        img: kogs,
                        title: "29",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Keys to Other Games, or KOGs are playable, blockchain-based collectibles for use across an entire gaming ecosystem and will be interoperable with several upcoming games. Players can earn and trade KOGs to build their collections, as well as use them to enter tournaments.",
                        imgAuthor: kogs,
                        nameAuthor: "Kogs",
                        twitterLink: "https://twitter.com/KOGS_GG",
                        websiteLink: "https://kogs.gg/",
                        collectionName: "kogsofficial",
                    },
                    {
                        id: 30,
                        img: koloblok,
                        title: "30",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "The incredible mix of Collectible, Playable, Redeemable, and Gear NFTs.",
                        imgAuthor: koloblok,
                        nameAuthor: "KoloBok Adventures",
                        twitterLink: "https://twitter.com/kolobok_io",
                        websiteLink: "https://wax.kolobok.io/",
                        collectionName: "k2adventures",
                    },
                    // {
                    //     id: 34,
                    //     img: milliononmars,
                    //     title: "34",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Million On Mars is a 2d play-to-earn game centered around land development on the red planet. Players must work together to build a thriving settlement of over a million people driven by the player-owned economy of resources they scavenge, grow, process, and create. Players will use blueprint NFT's to construct buildings on Land and unlock crafting actions that can be done by them or other players. ",
                    //     imgAuthor: milliononmars,
                    //     nameAuthor: "Million on Mars",
                    //     twitterLink: "http://twitter.com/milliononmars",
                    //     websiteLink: "https://milliononmars.com",
                    //     collectionName: "onmars",
                    // },
                    // {
                    //     id: 35,
                    //     img: monsterfight,
                    //     title: "35",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Monster Fight is a NFT game that allows you to hatch and breed eggs into monsters. Game modes available will be PvP and adventure. Each egg has a rarity chance inside. For more information please visit our website.",
                    //     imgAuthor: monsterfight,
                    //     nameAuthor: "Monster Fight",
                    //     twitterLink: "https://twitter.com/monsterfinft",
                    //     websiteLink: "https://monsterfi.io",
                    //     collectionName: "monsterfight",
                    // },
                    {
                        id: 37,
                        img: nftbattleminers,
                        title: "37",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Collection of NFTs that is used in NBM metaverse. NFT cards are used for mining crypto resources, WAX, NBM NFTs, Partnership pool NFTs and for future PVP battles.",
                        imgAuthor: nftbattleminers,
                        nameAuthor: "NFT Battle Miners",
                        twitterLink: "https://twitter.com/NFTBattleMiners",
                        websiteLink: "https://nftbattleminers.com/",
                        collectionName: "battleminers",
                    },
                    {
                        id: 38,
                        img: nftpandaworldoffantasy,
                        title: "38",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Welcome to the hungest epic fantasy world of the NFT Panda Multiverse NFT Panda World of Fantasy is an RPG game on the WAX blockchain. Collect the most epic Panda Heroes and protect the lands of Elgard. Play and earn!",
                        imgAuthor: nftpandaworldoffantasy,
                        nameAuthor: "NFT Panda World of Fantasy",
                        twitterLink: "https://twitter.com/NftPanda",
                        websiteLink: "https://nftpanda.space/",
                        collectionName: "nftpandawaxp",
                    },
                    {
                        id: 39,
                        img: novarally,
                        title: "39",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "01 Earn In-Game Currency Nova Rally NFTs passively earn in-game tokens 02 Take part in Races on WAX Blockchain... Select a combination of one vehicle and 2 characters and submit them into to Daily Races. 03 A Winner is Crowned... Our Deterministic Racing Game Engine combines the car rating and special hidden character perks to calculate the results of the race. 04 Win Prizes and Rewards! The Winner of a race recieves Character Shards to build up your racing roster!",
                        imgAuthor: novarally,
                        nameAuthor: "NovaRally",
                        twitterLink: "https://twitter.com/NovaRallyWAX",
                        websiteLink: "https://www.novarally.io/",
                        collectionName: "novarallywax",
                    },
                    {
                        id: 40,
                        img: novopangea,
                        title: "40",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "THE BEGINNING OF YOUR EMPIRE! BUILD YOUR ECONOMY - Acquire Land, Buildings, Facilities, Mines and Skilled Workers to produce resources and economic growth in Novopangea.",
                        imgAuthor: novopangea,
                        nameAuthor: "NovoPangea",
                        twitterLink: "https://twitter.com/novopangeaio",
                        websiteLink: "https://novopangea.io/",
                        collectionName: "novopangeaio",
                    },
                    // {
                    //     id: 43,
                    //     img: pagangods,
                    //     title: "43",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Pagan Gods is a blockchain game with dark slavic lore. PvE and PvP for $FUR and unique NFTs. Fight and earn!",
                    //     imgAuthor: pagangods,
                    //     nameAuthor: "Pagan Gods",
                    //     twitterLink: "https://twitter.com/InoWorlds",
                    //     websiteLink: "https://pagangods.io",
                    //     collectionName: "pagangodsapp",
                    // },
                    {
                        id: 44,
                        img: prospectors,
                        title: "44",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Massive Multiplayer Online Real-Time Economic Strategy Game. MINE GOLD - BUILD YOUR WORLD",
                        imgAuthor: prospectors,
                        nameAuthor: "Prospectors",
                        twitterLink: "https://twitter.com/prospectorsgame",
                        websiteLink: "https://prospectors.io",
                        collectionName: "prospectorsa",
                    },
                    {
                        id: 45,
                        img: radaquest,
                        title: "45",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Rada Quest TCG is a game that combines real-time strategy and card collecting. You can produce resources in your realm, power up your deck and earn exclusive rewards in events and quests.",
                        imgAuthor: radaquest,
                        nameAuthor: "Rada Quest",
                        twitterLink: "https://twitter.com/RadaQuestTCG",
                        websiteLink: "https://radaquest.net/",
                        collectionName: "radaquesttcg",
                    },
                    {
                        id: 46,
                        img: rplanet,
                        title: "46",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "RPlanet assets are playable, blockchain-based collectibles for use in the upcoming free-to-play online step-by-step NFT-card game. In the game, players compete with each other in separate rounds using the cards and extraordinary forces from the evacuated beasts. Players can trade RPlanet assets (elements and tools) to build their collections, as well as use them for playing after the game will be released.",
                        imgAuthor: rplanet,
                        nameAuthor: "RPlanet",
                        twitterLink: "https://twitter.com/RPLANETio",
                        websiteLink: "https://rplanet.io",
                        collectionName: "rplanet",
                    },
                    // {
                    //     id: 48,
                    //     img: spaceheroes,
                    //     title: "48",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Welcome new Heroes to Space Heroes Game!! SH is a metaverse where you not only play games and have a lot of fun, but you can also own properties in this metaverse and run different activities, which will provide valuable goods called NFTs. These NFTs have many varieties and different uses, but they always leave passive and active earnings for the players, making Space Heroes a Play-To-Earn game managed by the community and the creators as a team. Throughout the development of this project we expanded to other blockchains and partnered with other projects allowing them to have their own space and environment within our metaverse. SH has as a powerful weapon the wonderful community that has supported all the changes and evolutions of the project. From our website we want to say THANKS! to our community. If you are looking for a long-term project and a strong community: Welcome to SH!",
                    //     imgAuthor: spaceheroes,
                    //     nameAuthor: "Space Heroes",
                    //     twitterLink: "https://twitter.com/nftspaceheroes",
                    //     websiteLink: "https://spaceheroes.world",
                    //     collectionName: "spaceheroes1",
                    // },
                    {
                        id: 49,
                        img: splinterlands,
                        title: "49",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Splinterlands is the next generation of collectible card games. It allows players to collect, trade, and battle with provably scarce digital collectibles with real world value.",
                        imgAuthor: splinterlands,
                        nameAuthor: "SplinterLands",
                        twitterLink: "https://twitter.com/splinterlands",
                        websiteLink: "https://splinterlands.com/",
                        collectionName: "splintrlands",
                    },
                    {
                        id: 50,
                        img: theadventurersguild,
                        title: "50",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "A NFT-powered game on the WAX Blockchain, where every skill, item, and more are Non-Fungible Tokens. Currently in closed beta, players can battle foes and take quests to find crafting materials and the GUILD token! Developed by SixPM Software and NeftyBlocks.",
                        imgAuthor: theadventurersguild,
                        nameAuthor: "The Adventurers Guild",
                        twitterLink: "https://twitter.com/guildcards",
                        websiteLink: "https://theadventurersguild.io",
                        collectionName: "tag",
                    },
                    {
                        id: 51,
                        img: theforgearena,
                        title: "51",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "The Forge Arena is the first competitive FPS video game to utilize blockchain technology through NFTS & Play-to-Earn mechanics. Aimed to be the world's first Esport on the Blockchain, The Forge Arena offers an unique user powered and user owned experience that is unparalleled in the space. The Forge Arena currently has two game modes, hybrid and deathmatch, both of which are aimed toward a fun, controlled and intense combat experience.",
                        imgAuthor: theforgearena,
                        nameAuthor: "The Forge Arena",
                        twitterLink: "https://twitter.com/theforgearena",
                        websiteLink: "https://gen-e.io/",
                        collectionName: "forge.gene",
                    },
                    {
                        id: 52,
                        img: theupliftworld,
                        title: "52",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Step into a new world - Entirely made by you. The Uplift is a massive multiplayer universe powered by blockchain! Build, connect with other players, join events, mine & discover crypto rewards and much more!",
                        imgAuthor: theupliftworld,
                        nameAuthor: "The Uplift World",
                        twitterLink: "https://twitter.com/theupliftworld",
                        websiteLink: "https://theuplift.world",
                        collectionName: "upliftworld",
                    },
                    // {
                    //     id: 53,
                    //     img: trainofthecentury,
                    //     title: "53",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Train of the Century is a brand new collectible NFT trading card game. There are different types of cards in Train of the Century which you can use to build your very own train. You'll be able combine Locomotives, Railcars, and more. Have a completed train? As a Railroader, you'll be able to load up on freight or passengers, plan routes, set a train schedule, and earn the in-game currency — TOCIUM.",
                    //     imgAuthor: trainofthecentury,
                    //     nameAuthor: "Train of The Century",
                    //     twitterLink: "https://twitter.com/CenturyTrain",
                    //     websiteLink: "https://trains.cards/",
                    //     collectionName: "centurytrain",
                    // },
                    {
                        id: 54,
                        img: warsaken,
                        title: "54",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "Not your father's war game",
                        imgAuthor: warsaken,
                        nameAuthor: "Warsaken",
                        twitterLink: "https://twitter.com/thewarsaken",
                        websiteLink: "https://warsaken.com",
                        collectionName: "warsaken",
                    },
                    {
                        id: 56,
                        img: wombatdungeonmaster,
                        title: "56",
                        tags: "/more-info",
                        type: "P2E",
                        desc: "In a fight against predatory invaders, the mighty Wombat Warriors are summoned to stand their ground! In this collection inspired by the pop-culture, Wombat Warriors are preparing to fight, and they grabbed their entire arsenal with them!  Collect the in-battle moments capturing their power stances and awesome weapons in varied stunning environs — and use them for your dungeon runs in the upcoming Dungeon Master staking game!",
                        imgAuthor: wombatdungeonmaster,
                        nameAuthor: "Wombat Champs",
                        twitterLink: "https://twitter.com/adoptwombat",
                        websiteLink: "https://getwombat.io/",
                        collectionName: "wombatchamps",
                    },
                    // {
                    //     id: 57,
                    //     img: xpansion,
                    //     title: "57",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Xpansion is a decentralized 4x strategy game set in the near future. Faced with a dying world, the remnants of Earths' population have launched expedition waves to settle the habitable worlds surrounding nearby stars.",
                    //     imgAuthor: xpansion,
                    //     nameAuthor: "Xpansion",
                    //     twitterLink: "https://twitter.com/xpsgame",
                    //     websiteLink: "https://xpsgame.io/",
                    //     collectionName: "xpansiongame",
                    // },
                    // {
                    //     id: 58,
                    //     img: zendodo,
                    //     title: "58",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Zendodo is a NFT collection that lives on the WAX blockchain — starting as a mergeable collectible, The ZenMother has many more plans in the pipeline.",
                    //     imgAuthor: zendodo,
                    //     nameAuthor: "Zendodo",
                    //     twitterLink: "https://twitter.com/ZendodoParty",
                    //     websiteLink: "https://zendodo.io/",
                    //     collectionName: "zendodoparty",
                    // },
                    // {
                    //     id: 59,
                    //     img: zosnew,
                    //     title: "59",
                    //     tags: "/more-info",
                    //     type: "P2E",
                    //     desc: "Zombie Outbreak Survival (ZOS) is a apocalypse survival NFT based game built on the WAX blockchain.  ZOS takes place in the town of Nitel during a zombie outbreak and follows survivors as they scavenge, fight and eventually rebuild in the post-apocalyptic world.  ZOS aims to blend the grittiness and nail biting risks of the survival gaming genre, with the ownership and earning mechanic of an NFT game, where players not only survive but thrive!",
                    //     imgAuthor: zosnew,
                    //     nameAuthor: "Zombie Outbreak Survival",
                    //     twitterLink: "https://twitter.com/ZosWax",
                    //     websiteLink: "https://play.zos.world/",
                    //     collectionName: "zos",
                    // },
                    
                ]
            },
            // Market Places
            {
                id: 2,
                dataContent: [
                    {
                        id: 1,
                        img: virl,
                        title: "1",
                        tags: "/more-info",
                        type: "vIRL Market",
                        desc: "vIRL™ Market is the official WAX Studios NFT marketplace, where gamers and collectors can buy, sell and trade utility-based NFTs and vIRLs™ (Virtual in Real Life Items). It’s also home to the world’s hottest NFT creators and game developers – giving them the power to mint and launch NFT collections and in-game items in minutes.",
                        imgAuthor: virl,
                        nameAuthor: "Virl Marketplace",
                        twitterLink: "https://twitter.com/WAX_io",
                        websiteLink: "https://virl.com/",
                        collectionName: "null",
                        },
                    {
                        id: 2,
                        img: neftyblocks,
                        title: "1",
                        tags: "/more-info",
                        type: "Market Place",
                        desc: "Your home for NFTs. Effortlessly buy, sell and drop NFTs using community-driven tools.",
                        imgAuthor: neftyblocks,
                        nameAuthor: "Nefty Blocks",
                        twitterLink: "https://twitter.com/neftyblocks",
                        websiteLink: "https://neftyblocks.com/",
                        collectionName: "null",
                        },
                        {
                        id: 3,
                        img: atomichub,
                        title: "2",
                        tags: "/more-info",
                        type: "Market Place",
                        desc: "True ownership of digital goods. Buy, trade & create non-fungible tokens on the world's busiest #NFT marketplace.",
                        imgAuthor: atomichub,
                        nameAuthor: "AtomicHub",
                        twitterLink: "https://twitter.com/atomichub",
                        websiteLink: "https://wax.atomichub.io/",
                        collectionName: "null",
                        },
                        {
                        id: 4,
                        img: nfthive,
                        title: "3",
                        tags: "/more-info",
                        type: "Market Place",
                        desc: "#NFT Marketplace on the $WAX Blockchain.",
                        imgAuthor: nfthive,
                        nameAuthor: "NFT Hive",
                        twitterLink: "https://twitter.com/nfthiveio",
                        websiteLink: "https://nfthive.io/",
                        collectionName: "null",
                        },
                        {
                        id: 5,
                        img: droppp,
                        title: "4",
                        tags: "/more-info",
                        type: "Market Place",
                        desc: "NFT drops and market for fans and collectors of pop culture. Built on #WAX",
                        imgAuthor: droppp,
                        nameAuthor: "Droppp",
                        twitterLink: "https://twitter.com/Dropppio",
                        websiteLink: "https://droppp.io/",
                        collectionName: "null",
                        },
                        // {
                        // id: 6,
                        // img: waxstash,
                        // title: "4",
                        // tags: "/more-info",
                        // type: "Market Place",
                        // desc: "WAX NFT Marketplace, PackBreaks & CPU Rental.",
                        // imgAuthor: waxstash,
                        // nameAuthor: "WaxStash",
                        // twitterLink: "https://twitter.com/waxstash",
                        // websiteLink: "https://waxstash.io/",
                        // collectionName: "null",
                        // },
                        // {
                        // id: 7,
                        // img: yoshidrops,
                        // title: "5",
                        // tags: "/more-info",
                        // type: "Market Place",
                        // desc: "We empower musicians to create MUSIC #NFTs and reach a new audience from both web3/Web2.",
                        // imgAuthor: yoshidrops,
                        // nameAuthor: "YoshiDrops",
                        // twitterLink: "https://twitter.com/YoshiDrops",
                        // websiteLink: "https://www.yoshidrops.com/",
                        // collectionName: "null",
                        // },
                        // {
                        // id: 8,
                        // img: lgndart,
                        // title: "6",
                        // tags: "/more-info",
                        // type: "Market Place",
                        // desc: "LGND is a curated #NFT art platform built by artists, for artists. Featuring LGND drops & our favorite industry-related stories.",
                        // imgAuthor: lgndart,
                        // nameAuthor: "LGND Art",
                        // twitterLink: "https://twitter.com/lgndart",
                        // websiteLink: "https://lgnd.art/",
                        // collectionName: "null",
                        // },
                        {
                        id: 9,
                        img: chainchamps,
                        title: "7",
                        tags: "/more-info",
                        type: "Market Place",
                        desc: "Chain Champs is the fastest NFT marketplace on WAX! Find the best NFT deals on our live sale feed, and try your luck opening NFT packs in our pack simulator!",
                        imgAuthor: chainchamps,
                        nameAuthor: "Chain Champs",
                        twitterLink: "https://twitter.com/Chain_Champs",
                        websiteLink: "https://chainchamps.com/",
                        collectionName: "null",
                        },                        
                ]
            },
            // DEX's
            {
                id: 3,
                dataContent: [
                    {
                        id: 1,
                        img: alcor,
                        title: "1",
                        tags: "/more-info",
                        type: "DEX",
                        desc: "The first self-listing DEX",
                        imgAuthor: alcor,
                        nameAuthor: "Alcor Exchange",
                        twitterLink: "https://twitter.com/alcorexchange",
                        websiteLink: "https://wax.alcor.exchange/",
                        collectionName: "null",
                    },
                                    {
                                    id: 2,
                                    img: defibox,
                                    title: "2",
                                    tags: "/more-info",
                                    type: "DEX",
                                    desc: "One-stop DeFi Application Platform on Multi-chain. ",
                                    imgAuthor: defibox,
                                    nameAuthor: "Defi Box",
                                    twitterLink: "https://twitter.com/Defiboxofficial",
                                    websiteLink: "https://wax.defibox.io/",
                                    collectionName: "null",
                                    },
                                    {
                                        id: 3,
                                        img: adex,
                                        title: "3",
                                        tags: "/more-info",
                                        type: "DEX",
                                        desc: "The A-DEX is a peer-to-peer system designed for exchanging cryptocurrencies on the Antelope(EOSIO) blockchains.",
                                        imgAuthor: adex,
                                        nameAuthor: "A-DEX",
                                        twitterLink: "https://x.com/a_dex_official",
                                        websiteLink: "https://a-dex.io/",
                                        collectionName: "null",
                                    },
                                    {
                                    id: 4,
                                    img: tacoswap,
                                    title: "4",
                                    tags: "/more-info",
                                    type: "DEX",
                                    desc: "Taco Swap is a user-oriented, decentralized exchange on the WAX blockchain, allowing all users to swap their tokens with low market fees based on their TACO staked.",
                                    imgAuthor: tacoswap,
                                    nameAuthor: "Taco Swap",
                                    twitterLink: "https://x.com/tacostudios_io",
                                    websiteLink: "https://swap.tacocrypto.io/",
                                    collectionName: "null",
                                    },
                                    {
                                    id: 5,
                                    img: waxonedge,
                                    title: "5",
                                    tags: "/more-info",
                                    type: "DEX",
                                    desc: "WaxOnEdge is a cutting-edge trading platform designed specifically for advanced users seeking a seamless and efficient trading experience. The platform boasts a comprehensive set of features, including swap trading aggregation, smart order routing, in-depth market analysis, and an advanced trading desk.",
                                    imgAuthor: waxonedge,
                                    nameAuthor: "WaxOnEdge",
                                    twitterLink: "https://x.com/WaxOnEdge",
                                    websiteLink: "https://waxonedge.app/",
                                    collectionName: "null",
                                    },
                        
                    
                            ]
            },
            // Exchanges
            {
                id: 4,
                dataContent: [
                        {
                        id: 4,
                        img: uniswap,
                        title: "4",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "The largest onchain marketplace. Buy and sell crypto on Ethereum and 7+ other chains.",
                        imgAuthor: uniswap,
                        nameAuthor: "UniSwap",
                        twitterLink: "https://twitter.com/Uniswap",
                        websiteLink: "https://app.uniswap.org/#/swap",
                        collectionName: "null",
                        },
                        {
                        id: 5,
                        img: binance,
                        title: "5",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "The world's leading blockchain ecosystem and digital asset exchange.",
                        imgAuthor: binance,
                        nameAuthor: "Binance",
                        twitterLink: "https://twitter.com/binance",
                        websiteLink: "https://www.binance.com/",
                        collectionName: "null",
                        },
                        {
                        id: 6,
                        img: kucoin,
                        title: "6",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "Find the next crypto gem on KuCoin, the People's Exchange.",
                        imgAuthor: kucoin,
                        nameAuthor: "KuCoin",
                        twitterLink: "https://twitter.com/kucoincom",
                        websiteLink: "https://www.kucoin.com/",
                        collectionName: "null",
                        },
                        {
                        id: 7,
                        img: bingx,
                        title: "7",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "Empowering Traders. Elevate your crypto trading game at BingX.",
                        imgAuthor: bingx,
                        nameAuthor: "BingX",
                        twitterLink: "https://twitter.com/BingXOfficial",
                        websiteLink: "https://bingx.com/en-us/",
                        collectionName: "null",
                        },
                        {
                        id: 8,
                        img: okx,
                        title: "8",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "Faster, better, stronger than your average crypto exchange",
                        imgAuthor: okx,
                        nameAuthor: "OKX Exchange",
                        twitterLink: "https://twitter.com/okx",
                        websiteLink: "https://www.okx.com/trade-spot/waxp-usdt?channelid=1902090",
                        collectionName: "null",
                        },
                        {
                        id: 9,
                        img: bybit,
                        title: "9",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "Unleashing the full potential of crypto Web3 with next generation tools, support, and opportunities",
                        imgAuthor: bybit,
                        nameAuthor: "ByBit",
                        twitterLink: "https://twitter.com/Bybit_Official",
                        websiteLink: "https://www.bybit.com/en/trade/spot/WAXP/USDT?affiliate_id=9763",
                        collectionName: "null",
                        },
                        {
                        id: 10,
                        img: bitget,
                        title: "10",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "World's leading cryptocurrency exchange and web3 company.",
                        imgAuthor: bitget,
                        nameAuthor: "Bitget",
                        twitterLink: "https://twitter.com/bitgetglobal",
                        websiteLink: "https://www.bitget.com/spot/WAXPUSDT?channelCode=42xn&vipCode=sq59&languageType=0",
                        collectionName: "null",
                        },
                        {
                            id: 11,
                            img: bitmart,
                            title: "11",
                            tags: "/more-info",
                            type: "Exchange",
                            desc: "Your Trusted Digital Asset Exchange. Buy, trade, and hold 1500+ crypto instantly",
                            imgAuthor: bitmart,
                            nameAuthor: "BitMart",
                            twitterLink: "https://x.com/BitMartExchange",
                            websiteLink: "https://www.bitmart.com",
                            collectionName: "null",
                            },
                        {
                        id: 12,
                        img: xtcom,
                        title: "12",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "XT.com Exchange Gateway to Digital Assets, Highway to Wealth!",
                        imgAuthor: xtcom,
                        nameAuthor: "XT.com",
                        twitterLink: "https://twitter.com/XTexchange",
                        websiteLink: "https://www.xt.com/en/trade/waxp_usdt?ref=ZHOJUG5",
                        collectionName: "null",
                        },
                        {
                        id: 13,
                        img: huobi,
                        title: "13",
                        tags: "/more-info",
                        type: "Exchange",
                        desc: "Leading Crypto Exchange!",
                        imgAuthor: huobi,
                        nameAuthor: "Huobi",
                        twitterLink: "https://twitter.com/HuobiGlobal",
                        websiteLink: "https://www.huobi.com/en-us/",
                        collectionName: "null",
                        },
                        
                    
                ]
            },
            // DeFi
            {
                id: 5,
                dataContent: [
                    {
                        id: 1,
                        img: waxfusion,
                        title: "1",
                        tags: "/more-info",
                        type: "DeFi",
                        desc: "WAX Fusion is a Liquid Staking protocol on WAX Blockchain. WAX Fusion offers - Liquid Staking: Get access to your capital while still earning staking rewards. CPU Rental: Get CPU for your WAX wallet at the lowest rate on the market. WaxFusion's code is 100% open source. Anyone is free to audit it themselves and verify that it's secure.",
                        imgAuthor: waxfusion,
                        nameAuthor: "WAX FUSION",
                        twitterLink: "https://x.com/waxfusion_io",
                        websiteLink: "https://waxfusion.io/",
                        collectionName: "null",
                        },
                    {
                        id: 2,
                        img: waxdao,
                        title: "2",
                        tags: "/more-info",
                        type: "DeFi",
                        desc: "Get your project up and running within minutes. No fuss, no price gouging. Accessible tools for everyone, powered by WaxDAO. WaxDAO allows creators on WAX to transform their ideas into reality, without needing to learn how to code.",
                        imgAuthor: waxdao,
                        nameAuthor: "WaxDAO",
                        twitterLink: "https://twitter.com/waxdao_io",
                        websiteLink: "https://waxdao.io/",
                        collectionName: "null",
                        },
                        {
                            id: 2,
                            img: alcorexchange,
                            title: "2",
                            tags: "/more-info",
                            type: "DeFi",
                            desc: "Manage Liquidity pools & stake your liquidity positions in farms.",
                            imgAuthor: alcorexchange,
                            nameAuthor: "Alcor Exchange Earn",
                            twitterLink: "https://twitter.com/alcorexchange",
                            websiteLink: "https://alcor.exchange/positions",
                            collectionName: "null",
                            },
                        {
                        id: 2,
                        img: defiboxd,
                        title: "2",
                        tags: "/more-info",
                        type: "DeFi",
                        desc: "One-stop DeFi Application Platform on Multi-chain.",
                        imgAuthor: defiboxd,
                        nameAuthor: "DeFi Box",
                        twitterLink: "https://twitter.com/DefiboxOfficial",
                        websiteLink: "https://wax.defibox.io/",
                        collectionName: "null",
                        },
                        // {
                        // id: 3,
                        // img: cait,
                        // title: "3",
                        // tags: "/more-info",
                        // type: "DeFi",
                        // desc: "CAIT is a DeFi farming token on the WAX Blockchain.",
                        // imgAuthor: cait,
                        // nameAuthor: "CAIT",
                        // twitterLink: "https://twitter.com/CAITtoken",
                        // websiteLink: "https://caittoken.io/",
                        // collectionName: "null",
                        // },
                ]
            },
            // Block Explorers
            {
                id: 6,
                dataContent: [
                    {
                        id: 1,
                        img: waxblock,
                        title: "1",
                        tags: "/more-info",
                        type: "Block Explorer",
                        desc: "Mapping all of the activity on the WAX Blockchain",
                        imgAuthor: waxblock,
                        nameAuthor: "Wax Block",
                        twitterLink: "https://twitter.com/WAX_io",
                        websiteLink: "https://waxblock.io/",
                        collectionName: "null"
                      }, 
                    //   {
                    //     id: 2,
                    //     img: eosx,
                    //     title: "2",
                    //     tags: "/more-info",
                    //     type: "Block Explorer",
                    //     desc: "Block Explorer.",
                    //     imgAuthor: eosx,
                    //     nameAuthor: "EOSX",
                    //     twitterLink: "https://twitter.com/eosx_io",
                    //     websiteLink: "https://wax.eosx.io/",
                    //     collectionName: "null"
                    //   }, 
                      {
                        id: 4,
                        img: eosauthority,
                        title: "4",
                        tags: "/more-info",
                        type: "Block Explorer",
                        desc: "Block Explorer.",
                        imgAuthor: eosauthority,
                        nameAuthor: "EOS Authority",
                        twitterLink: "https://twitter.com/EOSauthority",
                        websiteLink: "https://wax.eosauthority.com/",
                        collectionName: "null"
                      },
                ]
            },
            // Developer Resources/Tools
            {
                id: 7,
                dataContent: [
                    {
                        id: 1,
                        img: waxdeveloperhive,
                        title: "1",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "The hub for blockchain development tutorials, quickstart guides, API documentation, and more. Everything you need to learn blockchain programming, blockchain dApp development on WAX, or duplicate an EOS dApp.",
                        imgAuthor:  waxdeveloperhive,
                        nameAuthor: "Wax Developer Hive",
                        twitterLink: "https://twitter.com/WAX_io",
                        websiteLink: "https://developer.wax.io/",
                        collectionName: "null",
                        },
                        {
                        id: 1,
                        img: awscdk,
                        title: "1",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Guide on how to run a WAX node using Amazon's AWS CDK",
                        imgAuthor:  awscdk,
                        nameAuthor: "AWS WAX Node Guide",
                        twitterLink: "https://twitter.com/WAX_io",
                        websiteLink: "https://aws.amazon.com/blogs/database/run-a-wax-blockchain-node-using-aws-cdk/",
                        collectionName: "null",
                        },
                        {
                        id: 2,
                        img: waxmainnetresourceusage,
                        title: "2",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "WAX Live Mainnet usage Stats",
                        imgAuthor:  waxmainnetresourceusage,
                        nameAuthor: "Wax Main Net Resource Usage",
                        twitterLink: "https://twitter.com/EOSauthority",
                        websiteLink: "https://eosauthority.com/mainnet_usage?network=wax",
                        collectionName: "null",
                        },
                        {
                        id: 2,
                        img: eospherehowto,
                        title: "2",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Technical Guides on How To for WAX Developers",
                        imgAuthor:  eospherehowto,
                        nameAuthor: "How to WAX Technical Guides",
                        twitterLink: "https://twitter.com/eosphere_io",
                        websiteLink: "https://medium.com/eosphere/wax-technical-how-to/home",
                        collectionName: "null",
                        },
                        {
                        id: 2,
                        img: headsupmonitoring,
                        title: "2",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "The HeadsUp Monitoring and Alerts Platform has been created by EOSphere for the Antelope Web3 community to encourage healthy infrastructure and blockchain service delivery. HeadsUp is designed for Antelope Node Operators to run a private instance of the platform on their internal network to monitor and alert on Antelope Nodeos, Hyperion History, Atomic Assets API. Alerts can be configured to notify via the GUI Website Frontend, Email and Slack. HeadsUp is simple to configure and deploy with docker compose which utilizes four docker containers editable in a single compose .yaml file. These containers consist of the HeadsUp Website, HeadsUp Ingestor and Postgres Database containers.",
                        imgAuthor:  headsupmonitoring,
                        nameAuthor: "HeadsUp",
                        twitterLink: "https://twitter.com/eosphere_io",
                        websiteLink: "https://github.com/eosphere/HeadsUp-Monitoring-Alerts",
                        collectionName: "null",
                        },  
                        {
                        id: 3,
                        img: eosswedendevelopersresources,
                        title: "3",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "valuable tools, marketplaces and resources on the WAX Blockchain",
                        imgAuthor:  eosswedendevelopersresources,
                        nameAuthor: "Eos Sweden Developer Resources",
                        twitterLink: "https://twitter.com/waxsweden",
                        websiteLink: "https://waxsweden.org/",
                        collectionName: "null",
                        },
                        {
                        id: 4,
                        img: voteproxyresearchportal,
                        title: "4",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "WAX Vote Proxy Research Portal.",
                        imgAuthor:  voteproxyresearchportal,
                        nameAuthor: "Vote Proxy Research Portal",
                        twitterLink: "https://twitter.com/eosaloha",
                        websiteLink: "https://www.alohaeos.com/vote/proxy/waxmain",
                        collectionName: "null",
                        },
                        {
                        id: 5,
                        img: atomicassetsdocumentation,
                        title: "5",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Atomic Assets NFT standard Documentation.",
                        imgAuthor:  atomicassetsdocumentation,
                        nameAuthor: "Atomic Assets Documentation",
                        twitterLink: "https://twitter.com/AtomicHub",
                        websiteLink: "https://atomicassets.io/",
                        collectionName: "null",
                        },
                        {
                        id: 6,
                        img: blockproducerresearchportal,
                        title: "6",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Block Producer Research Portal.",
                        imgAuthor:  blockproducerresearchportal,
                        nameAuthor: "Block Producer Research Portal",
                        twitterLink: "https://twitter.com/eosaloha",
                        websiteLink: "https://www.alohaeos.com/vote/waxmain",
                        collectionName: "null",
                        },
                        {
                        id: 7,
                        img: blockproducervalidator,
                        title: "7",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Block Producer bp.json Validator",
                        imgAuthor:  blockproducervalidator,
                        nameAuthor: "Block Producer Validator",
                        twitterLink: "https://twitter.com/EOS_Nation/",
                        websiteLink: "https://validate.eosnation.io/wax/",
                        collectionName: "null",
                        },
                        {
                        id: 8,
                        img: blockproducerbenchmark,
                        title: "8",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "WAX Block Producers Benchmarks.",
                        imgAuthor:  blockproducerbenchmark,
                        nameAuthor: "Block Producer Benchmark" ,
                        twitterLink: "https://twitter.com/eosaloha",
                        websiteLink: "https://www.alohaeos.com/tools/benchmarks#networkId=11&timeframeId=2&outliers=0",
                        collectionName: "null",
                        },
                        {
                        id: 9,
                        img: blockproducerreliabilitytracker,
                        title: "9",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "WAX Block Producer Reliability Tracker.",
                        imgAuthor:  blockproducerreliabilitytracker,
                        nameAuthor: "Block Producer Reliability Tracker",
                        twitterLink: "https://twitter.com/eosaloha",
                        websiteLink: "https://www.alohaeos.com/tools/reliability#networkId=11&timeframeId=8&sort=rank&sortDir=asc",
                        collectionName: "null",
                        },
                        {
                        id: 10,
                        img: greymassapi,
                        title: "10",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Greymass Public APIs.",
                        imgAuthor: greymassapi,
                        nameAuthor: "GreyMassApi",
                        twitterLink: "https://twitter.com/greymass",
                        websiteLink: "https://greymass.com/en/apis/",
                        collectionName: "null",
                        },
                        {
                        id: 11,
                        img: sentnl,
                        title: "11",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Blockchain Security Audit Service.",
                        imgAuthor: sentnl,
                        nameAuthor: "Sentnl",
                        twitterLink: "https://twitter.com/sentnl_io",
                        websiteLink: "https://www.sentnl.io/#services",
                        collectionName: "null",
                        },
                        {
                        id: 12,
                        img: dapplica,
                        title: "12",
                        tags: "/more-info",
                        type: "Developer Resource/Tool",
                        desc: "Dapplica Blockchain Services.",
                        imgAuthor:  dapplica,
                        nameAuthor: "DApplica",
                        twitterLink: "https://twitter.com/Dapplica1?lang=en",
                        websiteLink: "https://dapplica.io/services/",
                        collectionName: "null",
                        },
                        
                ]
            },
            // NFT Tools
            {
                id: 8,
                dataContent: [
                    // {
                    //     id: 1,
                    //     img: waxgg,
                    //     title: "1",
                    //     tags: "/more-info",
                    //     type: "NFT Tools",
                    //     desc: "Personalize your WAX account by uploading a photo linked to your identity that is shown on the sites which use our service.",
                    //     imgAuthor: waxgg,
                    //     nameAuthor: "Wax GG",
                    //     twitterLink: "https://twitter.com/AtomicHub",
                    //     websiteLink: "https://photo.wax.gg/",
                    //     collectionName: "null",
                    //     },
                        {
                        id: 2,
                        img: tacoapp,
                        title: "2",
                        tags: "/more-info",
                        type: "NFT Tools",
                        desc: "Spiciest NFT project on WAX. SALSA, Lettuce and an NFT-based platform into the metaverse's tastiest Taco!",
                        imgAuthor: tacoapp,
                        nameAuthor: "Taco App",
                        twitterLink: "https://twitter.com/tacowax",
                        websiteLink: "https://tacocrypto.io/",
                        collectionName: "null",
                        },
                        {
                        id: 3, 
                        img: bountyblokdroptool,
                        title: "3",
                        tags: "/more-info",
                        type: "NFT Tools",
                        desc: "Easily deploy, distribute and manage your NFT's. Free to use, the NFT drop tool allows you to distribute large amounts of NFTs to your following promoting engagement. If your an artist, brand, or part of an NFT project request access today!",
                        imgAuthor: bountyblokdroptool,
                        nameAuthor: "BountyBlok Drop Tool",
                        twitterLink: "https://twitter.com/bountyblok",
                        websiteLink: "https://wax.drop.bountyblok.io/",
                        collectionName: "null",
                        },
                        {
                        id: 4,
                        img: atomicassetsnftcreator,
                        title: "4",
                        tags: "/more-info",
                        type: "NFT Tools",
                        desc: "Create your NFT collection using AtomicHubs NFT Creator.",
                        imgAuthor: atomicassetsnftcreator,
                        nameAuthor: "Atomic Assets NFT Creator",
                        twitterLink: "https://twitter.com/AtomicHub",
                        websiteLink: "https://wax.atomichub.io/creator",
                        collectionName: "null",
                        },
                        { 
                        id: 5,
                        img: neftyblocksnftcreator,
                        title: "5",
                        tags: "/more-info",
                        type: "NFT Tools",
                        desc: "Create your WAX collection using NeftyBlocks NFT Creator.",
                        imgAuthor: neftyblocksnftcreator,
                        nameAuthor: "Nefty Blocks NFT Creator",
                        twitterLink: "https://twitter.com/neftyblocks",
                        websiteLink: "https://neftyblocks.com/creator",
                        collectionName: "null",
                        },
                        {   
                        id: 6,
                        img: packbreak,
                        title: "6",
                        tags: "/more-info",
                        type: "NFT Tools",
                        desc: "Buy or sell shares in NFT packs according to how many NFTs they contain.",
                        imgAuthor: packbreak,
                        nameAuthor: "Pack Break",
                        twitterLink: "https://twitter.com/waxstash",
                        websiteLink: "https://packbreaks.io/",
                        collectionName: "null",
                        },
                        
                    
                ]
            },
            // Market Ranking Sites
            {
                id: 9,
                dataContent: [
                    {
                        id: 1,
                        img: cryptoslam,
                        title: "1",
                        tags: "/more-info",
                        type: "Market Ranking",
                        desc: "The world's first multi-chain NFT data aggregator.",
                        imgAuthor: cryptoslam,
                        nameAuthor: "Crypto Slam",
                        twitterLink: "https://twitter.com/cryptoslamio",
                        websiteLink: "https://www.cryptoslam.io/",
                        collectionName: "null"
                      }, 
                    //   {
                    //     id: 2,
                    //     img: dapp,
                    //     title: "2",
                    //     tags: "/more-info",
                    //     type: "Market Ranking",
                    //     desc: "Leading analytics platform for over 5,000 dapps and 2,000 tokens on 22+ blockchains",
                    //     imgAuthor: dapp,
                    //     nameAuthor: "Dapp",
                    //     twitterLink: "https://twitter.com/dapp_com",
                    //     websiteLink: "https://www.dapp.com/search_product?chain=WAX",
                    //     collectionName: "null"
                    //   }, 
                      {
                        id: 3,
                        img: dappradar,
                        title: "3",
                        tags: "/more-info",
                        type: "Market Ranking",
                        desc: "The World's Dapp Store, home of $RADAR & the most trusted source for exploring dapp data.",
                        imgAuthor: dappradar,
                        nameAuthor: "DappRadar",
                        twitterLink: "https://twitter.com/dappradar",
                        websiteLink: "https://dappradar.com/chain/wax",
                        collectionName: "null"
                      }, 
                    //   {
                    //     id: 4,
                    //     img: niftycap,
                    //     title: "4",
                    //     tags: "/more-info",
                    //     type: "Market Ranking",
                    //     desc: "CoinMarket Cap For NFTs on WAX",
                    //     imgAuthor: niftycap,
                    //     nameAuthor: "NiftyCap",
                    //     twitterLink: "https://twitter.com/NiftyCap",
                    //     websiteLink: "https://niftycap.com/",
                    //     collectionName: "null"
                    //   }, 
                    //   {
                    //     id: 5,
                    //     img: reviewdapp,
                    //     title: "5",
                    //     tags: "/more-info",
                    //     type: "Market Ranking",
                    //     desc: "The leading dapp analytics platform, tracking 15 public chains with 4300+ dapps.",
                    //     imgAuthor: reviewdapp,
                    //     nameAuthor: "Dapp Review",
                    //     twitterLink: "https://twitter.com/dapp_review",
                    //     websiteLink: "https://dapp.review/search?q=wax",
                    //     collectionName: "null"
                    //   }, 
                      {
                        id: 6,
                        img: waxmarketcap,
                        title: "6",
                        tags: "/more-info",
                        type: "Market Ranking",
                        desc: "Data & Analytics for WAX NFT Collections.",
                        imgAuthor: waxmarketcap,
                        nameAuthor: "WAX Market Cap",
                        twitterLink: "https://twitter.com/wax_market_cap",
                        websiteLink: "https://waxmarketcap.com/",
                        collectionName: "null"
                      }, 
                ]
            },
            // Android / IOS Apps
            {
                id: 10,
                dataContent: [
                    // {
                    //     id: 1,
                    //     img: mynifties,
                    //     title: "1",
                    //     tags: "/more-info",
                    //     type: "Android / Ios App",
                    //     desc: "NFT Portfolio Tracking App. Tracks realtime prices of the NFTs inside any WAX wallet. Ios app Link: https://apps.apple.com/us/app/mynifties/id1609870024?platform=iphone    Android App Link: https://play.google.com/store/apps/details?id=com.blokcrafters.MyNifties&hl=en_US&gl=US",
                    //     imgAuthor: mynifties,
                    //     nameAuthor: "MyNifties",
                    //     twitterLink: "https://twitter.com/mynifties",
                    //     websiteLink: "https://mynifties.io/",
                    //     collectionName: "null",
                    // },
                    {
                        id: 2,
                        img: niftydrops,
                        title: "2",
                        tags: "/more-info",
                        type: "Android / Ios App",
                        desc: "The handy Nifty Drops app comprises a calendar of live and upcoming NFT drops where NFT collectors can quickly and easily swipe through to view the latest NFT drops and click directly through to the NFT marketplace to bid on and buy the NFTs that catch their eye. Ios Link:https://apps.apple.com/gb/app/niftydrops/id1573308126     Android Link: https://play.google.com/store/apps/details?id=com.niftyllc.niftydrops",
                        imgAuthor: niftydrops,
                        nameAuthor: "Nifty Drops",
                        twitterLink: "https://twitter.com/niftydrops",
                        websiteLink: "https://niftydrops.io/",
                        collectionName: "null",
                    },
                    /*{
                        id: 3,
                        img: shynapp,
                        title: "3",
                        tags: "/more-info",
                        type: "Android / Ios App",
                        desc: "Too many NFT projects to track? Download Shyn and see your entire portfolio in one place with real-time pricing! Ios Link: https://apps.apple.com/us/app/shyn-nft-portfolio/id1564333621     Android Link: https://play.google.com/store/apps/details?id=com.popstand.shyn",
                        imgAuthor: shynapp,
                        nameAuthor: "ShynApp",
                        twitterLink: "https://twitter.com/shynapp",
                        websiteLink: "https://shyn.io/",
                        collectionName: "null",
                    },*/
                    {
                        id: 4,
                        img: tacoappai,
                        title: "4",
                        tags: "/more-info",
                        type: "Android / Ios App",
                        desc: "Explore WAX blockchain with the best mobile app made for newbies and experts: Taco! Track your tokens, interact with other dApps, manage your accounts and organize your NFTs: everything from your pocket!  Ios Link: https://apps.apple.com/us/app/taco-wax-utility-tools/id1569255815     Android Link: https://play.google.com/store/apps/details?id=io.tacocrypto.app&hl=en_US&gl=US",
                        imgAuthor: tacoappai,
                        nameAuthor: "Taco App",
                        twitterLink: "https://twitter.com/tacowax",
                        websiteLink: "https://tacocrypto.io/",
                        collectionName: "null",
                    },
                    {
                        id: 5,
                        img: tokenhead,
                        title: "5",
                        tags: "/more-info",
                        type: "Android / Ios App",
                        desc: "TokenHead is the best way to organize all of your WAX NFTs and balances in one place. With features geared toward collectors and sellers you're always a step ahead. Ios Link: https://apps.apple.com/us/app/tokenhead/id1534958260      Android Link: https://play.google.com/store/apps/details?id=com.alohaeos.tokenhead",
                        imgAuthor: tokenhead,
                        nameAuthor: "Token Head",
                        twitterLink: "https://twitter.com/tokenheadio",
                        websiteLink: "https://www.tokenhead.io/",
                        collectionName: "null",
                    },
                    // {
                    //     id: 6,
                    //     img: waxtracker,
                    //     title: "6",
                    //     tags: "/more-info",
                    //     type: "Android / Ios App",
                    //     desc: "Want to view your WAX (Worldwide Asset eXchange) account balances at your fingertips? Or do you simply want to see and track other accounts? This is a great utility that lays out WAX information in a very easy-to-read manner. It helps you track your liquid, staking and refunding balances of WAX. Quantities are updated as frequently as you wish. Ios Link: https://apps.apple.com/us/app/wax-tracker/id1502651967      Android Link: https://play.google.com/store/apps/details?id=com.alohaeos.waxtracker&hl=en_US&gl=US",
                    //     imgAuthor: waxtracker,
                    //     nameAuthor: "Wax Tracker",
                    //     twitterLink: "https://twitter.com/eosaloha",
                    //     websiteLink: "https://www.alohaeos.com/",
                    //     collectionName: "null",
                    // },
                ]
            },
            // Live Drops Schedules
            {
                id: 11,
                dataContent: [
                    {
                        id: 1,
                        img: horizonwhite,
                        title: "1",
                        tags: "/more-info",
                        type: "Live Drop Schedule Site",
                        desc: "News, drops, analysis, articles on WAX Blockchain and NFT universe.",
                        imgAuthor: horizonwhite,
                        nameAuthor: "NFT Horizon",
                        twitterLink: "https://twitter.com/nfthorizon_io",
                        websiteLink: "https://nfthorizon.io/",
                        collectionName: "null",
                    },
                    // {
                    //     id: 2,
                    //     img: nftinsider,
                    //     title: "2",
                    //     tags: "/more-info",
                    //     type: "Live Drop Schedule Site",
                    //     desc: "The latest NFT news, interviews, drops & more. Pushing Web3 forwards one word at a time.",
                    //     imgAuthor: nftinsider,
                    //     nameAuthor: "NFT Insider",
                    //     twitterLink: "https://twitter.com/NFTInsider_io",
                    //     websiteLink: "https://nftinsider.io/",
                    //     collectionName: "null",
                    // },
                    {
                        id: 3,
                        img: niftydropsl,
                        title: "3",
                        tags: "/more-info",
                        type: "Live Drop Schedule Site",
                        desc: "📲 The first NFT Drops app and NFT Calendar💧 Helping NFT Collectors find NFT drops across all blockchains 🌎 & NFT Creators MARKET your NFT launches.",
                        imgAuthor: niftydropsl,
                        nameAuthor: "Nifty Drops",
                        twitterLink: "https://twitter.com/niftydrops",
                        websiteLink: "https://niftydrops.io/",
                        collectionName: "null",
                    },
                    // {
                    //     id: 4,
                    //     img: tokengamer,
                    //     title: "4",
                    //     tags: "/more-info",
                    //     type: "Live Drop Schedule Site",
                    //     desc: "The #1 platform for games utilizing blockchain technology. News, reviews, and NFT giveaways, since 2018. Managed by Rob, Co-host of the Mint One Podcast.",
                    //     imgAuthor: tokengamer,
                    //     nameAuthor: "Token Gamer",
                    //     twitterLink: "https://twitter.com/TokenGamerNews",
                    //     websiteLink: "https://tokengamer.io/",
                    //     collectionName: "null",
                    // },
                ]
            },
            // Wallets
            {
                id: 12,
                dataContent: [
                    {
                        id: 2,
                        img: wombat,
                        title: "2",
                        tags: "/more-info",
                        type: "Wallet",
                        desc: "Web3 gaming - For Everyone. Play. Own. Be your self.",
                        imgAuthor: wombat,
                        nameAuthor: "Wombat",
                        twitterLink: "https://twitter.com/adoptwombat",
                        websiteLink: "https://www.wombat.app/",
                        collectionName: "atomicwombat",
                    },
                    {
                        id: 1,
                        img: anchorwallet,
                        title: "1",
                        tags: "/more-info",
                        type: "Wallet",
                        desc: "Use the Anchor Wallet to seamlessly and securely interact with any EOSIO-based blockchain. Anchor is a security and privacy focused open-source digital wallet for all EOSIO-based networks.",
                        imgAuthor: anchorwallet,
                        nameAuthor: "Anchor",
                        twitterLink: "https://twitter.com/greymass",
                        websiteLink: "https://greymass.com/en/anchor/",
                        collectionName: "null",
                    },
                    {
                        id: 2,
                        img: cloudwallet,
                        title: "2",
                        tags: "/more-info",
                        type: "Wallet",
                        desc: "Simple. Instant. Secure. Global. Wallet",
                        imgAuthor: cloudwallet,
                        nameAuthor: "Cloud Wallet",
                        twitterLink: "https://twitter.com/wax_io",
                        websiteLink: "https://www.mycloudwallet.com/",
                        collectionName: "null",
                    },
                ]
            },
            // Funding
            {
                id: 13,
                dataContent: [
                    {
                        id: 1,
                        img: waxlabsfunding,
                        title: "1",
                        tags: "/more-info",
                        type: "Funding",
                        desc: "Choose how to add value to the community. Growing the WAX Blockchain through decentralization and innovation. Wax Labs allows proposers with a profile to submit a proposal they want to get funding for. For the proposal lifecycle process visit https://labs.wax.io",
                        imgAuthor: waxlabsfunding,
                        nameAuthor: "Wax Labs Funding",
                        twitterLink: "https://twitter.com/WAX_io",
                        websiteLink: "https://labs.wax.io/",
                        collectionName: "null",
                    },
                ]
            },
            // Communnity
            {
                id: 14,
                dataContent: [
                    {
                        id: 1,
                        img: wuffi,
                        title: "1",
                        tags: "/more-info",
                        type: "Community",
                        desc: "WUFFI is on a tail-wagging quest to be the top dog of community coins in web3. We’re fetching success through expanding our kennel of integrations, pouncing into diverse chains, diving into DeFi pools, and playing fetch in WUFFI's gaming world. In our pack, every bark counts — the stronger the howl, the mightier our mission!",
                        imgAuthor: wuffi,
                        nameAuthor: "WUFFI",
                        twitterLink: "https://twitter.com/wuffi_inu",
                        websiteLink: "https://www.wuffi.io/",
                        collectionName: "null",
                    },
                    {
                        id: 1,
                        img: wuffitap,
                        title: "1",
                        tags: "/more-info",
                        type: "Community",
                        desc: "",
                        imgAuthor: wuffitap,
                        nameAuthor: "Wuffi Tap",
                        twitterLink: "https://x.com/WUFFI_Inu",
                        websiteLink: "https://t.me/Wuffitap_Bot",
                        collectionName: "null",
                    },
                    {
                        id: 1,
                        img: waxdao,
                        title: "1",
                        tags: "/more-info",
                        type: "community",
                        desc: "Get your project up and running within minutes. No fuss, no price gouging. Accessible tools for everyone, powered by WaxDAO. WaxDAO allows creators on WAX to transform their ideas into reality, without needing to learn how to code.",
                        imgAuthor: waxdao,
                        nameAuthor: "WaxDAO",
                        twitterLink: "https://twitter.com/waxdao_io",
                        websiteLink: "https://waxdao.io/",
                        collectionName: "null",
                    },
                    {
                        id: 1,
                        img: illusivegg,
                        title: "1",
                        tags: "/more-info",
                        type: "Illusive GG",
                        desc: "Home of Crosschain Gaming: Connecting gamers with the best blockchain gaming projects by bridging the gap between multiple chainprotocols. Browse, find and play awesome blockchain games!",
                        imgAuthor: illusivegg,
                        nameAuthor: "Illusive GG",
                        twitterLink: "https://twitter.com/illusive_app",
                        websiteLink: "https://www.illusive.gg/",
                        collectionName: "null",
                    },
                    {
                        id: 1,
                        img: waxzilla,
                        title: "1",
                        tags: "/more-info",
                        type: "WaxZilla",
                        desc: "Give Home to your Community. Build your antelope community in just two steps. Keep the engagement lively and reward your members with NFT’s Merchandise and much more.",
                        imgAuthor: waxzilla,
                        nameAuthor: "WaxZilla",
                        twitterLink: "https://twitter.com/waxzillaio",
                        websiteLink: "https://waxzilla.io/",
                        collectionName: "null",
                    },
                    {
                        id: 1,
                        img: launchbagz,
                        title: "1",
                        tags: "/more-info",
                        type: "Community",
                        desc: "LaunchBAGZ is a token launchpad for the WAX ecosystem. Create customizable memecoins, enjoy fair launches, & benefit from automated liquidity pools.",
                        imgAuthor: launchbagz,
                        nameAuthor: "Launch Bagz",
                        twitterLink: "https://x.com/LaunchBAGZ",
                        websiteLink: "https://wax.launchbagz.com/",
                        collectionName: "null",
                    },
                    {
                        id: 2,
                        img: anyobservation,
                        title: "2",
                        tags: "/more-info",
                        type: "Educational Site",
                        desc: "Blockchain, NFT and Cryptocurrency education and Information available for everyone, anywhere.",
                        imgAuthor: anyobservation,
                        nameAuthor: "AnyObservation",
                        twitterLink: "https://twitter.com/anyobservation",
                        websiteLink: "https://academy.anyo.io/",
                        collectionName: "anyo.b1",
                    },
                    {
                        id: 1,
                        img: waxhub,
                        title: "1",
                        tags: "/more-info",
                        type: "WaxHub",
                        desc: "The WAX Hub is a community-driven program focused on supporting the growth and development of the WAX NFT ecosystem. Members contribute in various areas such as community management, content creation, social media, events, and tech support. By joining the WAX Hub, you become a vital part of the expansion of the WAX blockchain and gain access to exclusive rewards, early project access, and the opportunity to provide direct feedback to core developers. The program is open to anyone passionate and interested in being part of a vibrant community of creative individuals worldwide. Whether you're an enthusiast, leader, influencer, expert, content creator, student, or newcomer, the WAX Hub offers a chance to acquire valuable experience and skills in the blockchain industry.",
                        imgAuthor: waxhub,
                        nameAuthor: "WaxHub",
                        twitterLink: "https://twitter.com/wax_io",
                        websiteLink: "https://ambassador.wax.io/accounts/login/?next=/",
                        collectionName: "null",
                    }
                ]
            
            },
            // Learn
            {
                id: 15,
                dataContent: [
                    {
                        id: 2,
                        img: anyobservation,
                        title: "2",
                        tags: "/more-info",
                        type: "name",
                        desc: "",
                        imgAuthor: anyobservation,
                        nameAuthor: "AnyObservation",
                        twitterLink: "",
                        websiteLink: "",
                        collectionName: "null",
                    },
                    {
                        id: 2,
                        img: anyobservation,
                        title: "2",
                        tags: "/more-info",
                        type: "name",
                        desc: "",
                        imgAuthor: anyobservation,
                        nameAuthor: "AnyObservation",
                        twitterLink: "",
                        websiteLink: "",
                        collectionName: "null",
                    },
                ]
            
            },
        ]
    )
    const [visible , setVisible] = useState(15);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
    }

    const listBtn = useRef(null)
    const gridBtn = useRef(null)
    const listContent = useRef(null)
    const gridContent = useRef(null)
    

    const listToggle = () => {
        listBtn.current.classList.add('active');
        gridBtn.current.classList.remove('active');
        listContent.current.classList.add('open');
        gridContent.current.classList.remove('open');
    }
    const gridToggle = () => {
        gridBtn.current.classList.add('active');
        listBtn.current.classList.remove('active');
        gridContent.current.classList.add('open');
        listContent.current.classList.remove('open');
    }
     
      

    const [modalShow, setModalShow] = useState(false);
  return (
    <Fragment>
        <div className="flat-tabs items">
            <Tabs >
                <TabList>
                    {
                        dataTab.map(data=> (
                            <Tab key={data.id} >{data.title}</Tab>
                        ))
                    }
                </TabList>
                {
                    dataPanel.map(data =>(
                        <TabPanel key={data.id}>
                            <div className="option">
                                <h4 className="title">WaxDapps</h4>
                                <div className="view">
                                    <ul>
                                        <li onClick={listToggle} ref={listBtn} className="style1 grid active">
                                            <Link to="#">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z" fill="white"/>
                                                    <path d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z" fill="white"/>
                                                    <path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" fill="white"/>
                                                    <path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z" fill="white"/>
                                                </svg>
                                                <span>Grid</span>
                                            </Link>
                                        </li>
                                        <li onClick={gridToggle} ref={gridBtn} className="style2 list">
                                            <Link to="#">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 8H3C2.59 8 2.25 7.09333 2.25 6C2.25 4.90667 2.59 4 3 4H21C21.41 4 21.75 4.90667 21.75 6C21.75 7.09333 21.41 8 21 8Z" fill="#EBEBEB"/>
                                                    <path d="M21 14H3C2.59 14 2.25 13.0933 2.25 12C2.25 10.9067 2.59 10 3 10H21C21.41 10 21.75 10.9067 21.75 12C21.75 13.0933 21.41 14 21 14Z" fill="#EBEBEB"/>
                                                    <path d="M21 20H3C2.59 20 2.25 19.0933 2.25 18C2.25 16.9067 2.59 16 3 16H21C21.41 16 21.75 16.9067 21.75 18C21.75 19.0933 21.41 20 21 20Z" fill="#EBEBEB"/>
                                                </svg>
                                                <span>List</span>
                                            </Link>
                                        </li>
                                    </ul> 
                                </div>
                            </div>
                            
                            <div className="content-item open" ref={listContent}>
                                {  
                                    data.dataContent.slice(0,visible).map ((item,key)=>(

                                        <div key={key} className="col-item">
                                            <div className="sc-card-product menu_card style-h7">
                                                <div className="meta-info style">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={item.imgAuthor} alt="Axies" />
                                                        </div>
                                                        <div className="info">
                                                            <span>Creator</span>
                                                            <h6> 
                                                            <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}>{item.nameAuthor}
                                                            </Link></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-media">
                                                    <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}>
                                                        <img src={item.img} alt="Axies" />
                                                    </Link>
                                                </div>
                                                <div className="card-title">
                                                <h4> 
                                                    <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}
                                                        >{item.nameAuthor}</Link></h4>
                                                </div>
                                                <div className="meta-info">
                                                    <div className="author">
                                                    </div>
                                                </div>
                                            </div>   
                                        </div>
                                    ))
                                    
                                }
                                
                                {
                                    visible < data.dataContent.length && 
                                    <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                                        <Link to="#" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                    </div>
                                }
                            </div>
                            <div className="content-item2" ref={gridContent}>
                                {
                                    data.dataContent.slice(0,visible).map((item,index)=> (
                                    <div key={index} className="col-item">
                                        <div className="sc-card-product menu_card style-h7">
                                            <div className="wrap-media">
                                                <div className="card-media">
                                                    <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}>
                                                        <img src={item.imgAuthor} alt="Axies" />
                                                    </Link>                                                                                                            
                                                </div>
                                            </div>
                                            <div className="card-title">
                                                <p>{item.type}</p>
                                                <h4>
                                                    <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}>
                                                        {item.nameAuthor}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="meta-info style">
                                                <p>Description</p>
                                                <div className="author">
                                                    
                                                    <div className="info">
                                                        <h6> 
                                                        <Link to={item.tags} state={{ "collectionTitle": item.nameAuthor, "collectionImg": item.img, "collectionInfo": item.desc, "collectionTwitter": item.twitterLink, "collectionWebsite": item.websiteLink, "collection": item.collectionName, "collectionType": item.type }}
                                                            />{item.desc}</h6>

                                                    </div>
                                                </div>
                                            </div>     
                                         
                                        </div>   
                                    </div>
                                    ))
                                }
                                {
                                    visible < data.dataContent.length && 
                                    <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                                        <Link to="#" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                    </div>
                                }
                            </div>
                            
                        </TabPanel>
                    ))
                }
            </Tabs>
        </div>
        <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
         />
         
        
    </Fragment>
    
  )
  
}

export default ItemContent